<template>
<div class="p-grid p-fluid home">
    <div class="home">
        <div class="prosupport" style="background-image: url('./images/prefondo3.png');">
            <div class="p-grid">
                <div class="p-col-12 p-md-8">
                    <h3 style="color:#727376;"><strong>{{$t('About us')}}</strong></h3>
                    <p style="color:#727376;">
                        {{$t('Company Desc1')}}<br>
                        {{$t('Company Desc2')}}</p>
                </div>
                <div class="p-col-12 p-md-2"></div>
                <div class="p-col-12 p-md-2">
                    <img src="../assets/images/logo4.webp" width="120" height="120"/>
                </div>
            </div>
        </div>

        <div class="features" style="background:#727376;">
            <div class="p-grid" >
                <div class="p-col-12 p-md-3">
                    <div class="feature-card">
                        <!--<Accordion :activeIndex="0">-->
                        <Accordion>
                            <AccordionTab :header="$t('Company1')">
                                <p>{{$t('Company1_1')}}</p>
                                <p>{{$t('Company1_2')}}</p>
                                <p>{{$t('Company1_3')}}</p>
                            </AccordionTab>
                            <AccordionTab :header="$t('Company2')">
                                <p>{{$t('Company2_1')}}</p>
                                <p>{{$t('Company2_2')}}</p>
                                <p>{{$t('Company2_3')}}</p>
                            </AccordionTab>
                            <AccordionTab :header="$t('Company3')">
                                <p>{{$t('Company3_1')}}</p>
                                <p>{{$t('Company3_2')}}</p>
                                <p>{{$t('Company3_3')}}</p>
                            </AccordionTab>
                            <AccordionTab :header="$t('Company4')">
                                <p>{{$t('Company4_1')}}</p>
                                <p>{{$t('Company4_2')}}</p>
                                <p>{{$t('Company4_3')}}</p>
                            </AccordionTab>
                        </Accordion>
                        <br>
                        <div class="p-grid" >
                            <div class="p-col-12 p-md-12">
                                <Button :disabled ="$store.state.loading" :label="$t('Notificar1')" icon="pi pi-thumbs-up" class="p-mr-2 p-mb-2"></Button>
                            </div>
                            <div class="p-col-12 p-md-12">
                                <Button :disabled ="$store.state.loading" :label="$t('Notificar2')" icon="pi pi-thumbs-up" class="p-mr-2 p-mb-2"></Button>
                            </div> 
                        </div> 
                    </div>                    
                </div>
                <div class="p-col-12 p-md-6">
                    <div class="feature-card">
                        <br>
                        <div class="feature-card-detail">
                            <h3 style="color:#727376;"><strong>{{$t('Quienes somos')}}</strong></h3>
                            <br>
                            <div class="feature-name">{{$t('Visión')}}</div>
                            <p  style="font-size:13px;">{{$t('Vision-Desc')}}</p>
                            <br>
                            <div class="feature-name">{{$t('Nuestra identidad corporativa')}}</div>
                            <p  style="font-size:14px;">{{$t('Nuestra identidad corporativa Desc')}}</p>
                        </div>
                    </div>
                </div>
                <div class="p-col-12 p-md-3">
                    <div class="feature-card">
                        <img alt="templates" src="../assets/images/quote.webp"  width="220"/>
                        <div class="feature-card-detail">
                            <div class="feature-name">{{$t('¿QUIERES COTIZAR?')}}</div>
                            <p  style="font-size:12px;">{{$t('Cotizar Mens')}}</p>
                            <br><Button :disabled ="$store.state.loading" :label="$t('Más información')" icon="pi pi-thumbs-up" class="p-mr-2 p-mb-2"></Button>
                        </div>
                    </div>
                </div>


<!--
                <div class="p-col-12 p-md-3">
                    <div class="feature-card">
                        <br>
                        <div class="feature-card-detail">
                            <h3 style="color:#727376;"><strong>{{$t('Puntos SaBeé por cotización')}}</strong></h3>
                            <br>
                            <div class="feature-name">{{$t('Cotizar1')}}</div>
                            <p  style="font-size:12px;">{{$t('Cotizar1_1')}}</p>
                            <br>
                            <div class="feature-name">{{$t('Cotizar2')}}</div>
                            <p  style="font-size:12px;">{{$t('Cotizar2_1')}}</p>
                        </div>
                    </div>
                </div>
-->

          </div>
        </div>


	</div>


</div>
</template>

<script>

export default {
	components: {
    },
	data() {
		return {
            dark: false,
            images: [ 
				{ 
					"itemImageSrc": "images/banner1.jpg", 
					"thumbnailImageSrc": "images/banner1.jpg", 
					"alt": "", 
					"title": "Banner 1" 
				}, 
				{ 
					"itemImageSrc": "images/banner2.jpg", 
					"thumbnailImageSrc": "images/banner2.jpg", 
					"alt": "", 
					"title": "Banner 2" 
				}, 
				{ 
					"itemImageSrc": "images/banner3.jpg", 
					"thumbnailImageSrc": "images/banner3.jpg", 
					"alt": "", 
					"title": "Banner 2" 
				}
			],
			responsiveOptions: [
				{
                    breakpoint: '1024px',
                    numVisible: 5
                },
                {
                    breakpoint: '768px',
                    numVisible: 3
                },
                {
                    breakpoint: '560px',
                    numVisible: 1
                }
			]
			
		}
	},
	productService: null,
	eventService: null,
	created() {
	},
	mounted() {
	},
	methods: {
	},
	computed: {
        introductionClass() {
            return ['introduction', {'introduction-dark': true}];
        }
    }

}
</script>
